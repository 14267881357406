.header > ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}

.admin-home {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.event-name {
  font-family: 'Azonix', sans-serif;
  color: #fff;
  font-size: 25px;
}

.reviews-list {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.review-button {
  background-color: #171717;
  outline: 0;
  margin: 5px;
  padding: 15px;
  border: 0;
  border-radius: 4px;
  width: 250px;
  color: #fff;
  font-size: 1rem;
  transition: all 500ms;
}

.review-button:hover {
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  transform: translateY(-3px) scale(1.05);
  background-color: #fff;
  color: #171717;
}

.event {
  margin-bottom: 2.7rem;
}

.review-overlay {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.897);
  display: grid;
  place-items: center;
}

.review-card {
  width: 80%;
  height: 80%;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: space-around;
}

.review-card-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 10px;
  align-items: flex-end;
}

.review-card-nav > span {
  display: flex;
}

.collapse-review {
  background-color: #383737;
  height: max-content;
  padding: 8px;
  margin-top: 5px;
  color: #fff;
  border: 0;
  width: 100px;
  transition: all 500ms;
}

.collapse-review:hover {
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  background-color: #fff;
  transform: scale(1.02);
  color: #000;
}

.collapse-review:focus {
  outline: none;
  border: 0;
}

.review-event-name {
  font-family: 'Poppins', sans-serif;
  padding: 8px;
}

.event-review-no {
  font-family: 'Poppins', sans-serif;
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.review-body {
  height: 100%;
}

.no-teams {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin: 100px;
  font-size: 2rem;
}

.hackJudge-logo {
  width: 110px;
  height: 71.73px;
}

.dsc-logo {
  width: 320px;
  height: auto;
  background-color: black;
}

.loginHeader-ul {
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.login-dsc-logo {
  margin-left: auto;
}

.login-form-container {
  display: grid;
  flex-direction: column;
  overflow: hidden;
  place-items: center;
  padding-top: 40px;
}

.login-title {
  padding: 20px;
  font-family: 'Azonix', sans-serif;
  font-size: 3rem;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 81px;
  letter-spacing: 0;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .login-dsc-logo {
    display: none;
  }

  .loginHeader-ul {
    justify-content: center;
  }

  .login-form-container {
    padding-top: 0;
  }

  .login-title {
    padding: 0;
    font-size: 2rem;
    line-height: 60px;
  }

  .hackJudge-logo {
    width: 90px;
    height: 58.6px;
  }
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form > label {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .login-form > label {
    width: 90%;
    margin-top: 0;
  }

  .login-button {
    width: 90%;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .login-form > label {
    width: 60%;
    margin-top: 0;
  }

  .login-button {
    width: 60%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .login-form > label {
    width: 40%;
    margin-top: 0;
  }

  .login-button {
    width: 40%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .login-form > label {
    width: 30%;
    margin-top: 0;
  }

  .login-button {
    width: 30%;
  }
}

@media screen and (min-width: 1601px) {
  .login-button {
    width: 25%;
  }
}

.input-label-text {
  text-align: left;
  width: 100%;
  padding: 5px;
  color: #ddd;
}

.input-box {
  width: 100%;
  height: 3.4rem;
  background-color: #171717;
  color: white;
  border: 0;
  outline: none;
  padding: 12px;
  padding-left: 20px;
  border-radius: 4px;
  font-size: 15px;
}

.input-box:focus {
  border: 1px solid white !important;
}

.input-box::placeholder {
  color: rgb(151, 141, 141);
}

.input-box:hover {
  border: 1px solid gray;
}

.login-button {
  background-color: #0083ff;
  cursor: pointer;
  height: 3.2rem;
  margin: 10px;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 3px;
  transition: all 500ms;
}

.login-button:hover {
  background-color: #fff;
  color: #000;
}

input::placeholder {
  color: #e1e1e1;
}

.user-type-login-button > button {
  border: 0;
  outline: none;
  background-color: inherit;
  color: white;
  font-size: 15px;
  transition: all 500ms;
}

.user-type-login-button > button:hover {
  cursor: pointer;
  color: #426a8f;
  transform: scale(1.01);
}

.login-circle {
  background-color: #e1e1e1;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.loader-box {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 7px;
}

.red {
  background-color: red !important;
}

.hidden {
  display: hidden !important;
}

@keyframes phaser {
  0% { background-color: #fff; }
  25% { background-color: rgb(151, 141, 141); }
  50% { background-color: #fff; }
  75% { background-color: rgb(122, 117, 117); }
  100% { background-color: rgb(255, 255, 255); }
}

.login-circle-phasing {
  background-color: #000;
  animation-name: phaser;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

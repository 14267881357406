@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

@font-face {
  font-family: 'Azonix';
  src:
    url('./../assets/Azonix.otf'),
    url(https://db.onlinewebfonts.com/c/50cd2aad9c8f35800bb6beac3ad42f16?family=Azonix);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  background-image: url(../assets/Background.svg);
  background-repeat: unset;
  background-size: auto;
  color: white;
  padding: 20px;
}

.container {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

button:hover {
  cursor: pointer;
}

.login-error {
  width: 100%;
  color: red;
  font-size: 1.1rem;
  text-align: center;
  padding: 10px;
}

.links {
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.links button,
.links a,
.logout-button {
  font-family: 'Azonix', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  margin: 0 1rem;
  background-color: inherit;
  border: 0;
  transition: all 300ms;
}

.logout-button {
  border: 4px solid #122c44;
  border-radius: 4px;
  padding: 6px;
  padding-top: 10px;
  align-items: center;
}

.logout-button:hover,
.logout-button > a:hover {
  color: #04d9ff;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
}

.links > button:hover,
.links > a:hover {
  color: #04d9ff;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  transform: translateY(-3px) scale(1.05);
}

.links > button:focus {
  border: none;
  outline: none;
}

.active-link {
  color: #04d9ff;
}

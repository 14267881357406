@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap);
.hackJudge-logo {
  width: 110px;
  height: 71.73px;
}

.dsc-logo {
  width: 320px;
  height: auto;
  background-color: black;
}

.loginHeader-ul {
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.login-dsc-logo {
  margin-left: auto;
}

.login-form-container {
  display: grid;
  flex-direction: column;
  overflow: hidden;
  place-items: center;
  padding-top: 40px;
}

.login-title {
  padding: 20px;
  font-family: 'Azonix', sans-serif;
  font-size: 3rem;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: 81px;
  letter-spacing: 0;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .login-dsc-logo {
    display: none;
  }

  .loginHeader-ul {
    justify-content: center;
  }

  .login-form-container {
    padding-top: 0;
  }

  .login-title {
    padding: 0;
    font-size: 2rem;
    line-height: 60px;
  }

  .hackJudge-logo {
    width: 90px;
    height: 58.6px;
  }
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form > label {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .login-form > label {
    width: 90%;
    margin-top: 0;
  }

  .login-button {
    width: 90%;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .login-form > label {
    width: 60%;
    margin-top: 0;
  }

  .login-button {
    width: 60%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .login-form > label {
    width: 40%;
    margin-top: 0;
  }

  .login-button {
    width: 40%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .login-form > label {
    width: 30%;
    margin-top: 0;
  }

  .login-button {
    width: 30%;
  }
}

@media screen and (min-width: 1601px) {
  .login-button {
    width: 25%;
  }
}

.input-label-text {
  text-align: left;
  width: 100%;
  padding: 5px;
  color: #ddd;
}

.input-box {
  width: 100%;
  height: 3.4rem;
  background-color: #171717;
  color: white;
  border: 0;
  outline: none;
  padding: 12px;
  padding-left: 20px;
  border-radius: 4px;
  font-size: 15px;
}

.input-box:focus {
  border: 1px solid white !important;
}

.input-box::-webkit-input-placeholder {
  color: rgb(151, 141, 141);
}

.input-box:-ms-input-placeholder {
  color: rgb(151, 141, 141);
}

.input-box::-ms-input-placeholder {
  color: rgb(151, 141, 141);
}

.input-box::placeholder {
  color: rgb(151, 141, 141);
}

.input-box:hover {
  border: 1px solid gray;
}

.login-button {
  background-color: #0083ff;
  cursor: pointer;
  height: 3.2rem;
  margin: 10px;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 3px;
  transition: all 500ms;
}

.login-button:hover {
  background-color: #fff;
  color: #000;
}

input::-webkit-input-placeholder {
  color: #e1e1e1;
}

input:-ms-input-placeholder {
  color: #e1e1e1;
}

input::-ms-input-placeholder {
  color: #e1e1e1;
}

input::placeholder {
  color: #e1e1e1;
}

.user-type-login-button > button {
  border: 0;
  outline: none;
  background-color: inherit;
  color: white;
  font-size: 15px;
  transition: all 500ms;
}

.user-type-login-button > button:hover {
  cursor: pointer;
  color: #426a8f;
  transform: scale(1.01);
}

.login-circle {
  background-color: #e1e1e1;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.loader-box {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 7px;
}

.red {
  background-color: red !important;
}

.hidden {
  display: hidden !important;
}

@keyframes phaser {
  0% { background-color: #fff; }
  25% { background-color: rgb(151, 141, 141); }
  50% { background-color: #fff; }
  75% { background-color: rgb(122, 117, 117); }
  100% { background-color: rgb(255, 255, 255); }
}

.login-circle-phasing {
  background-color: #000;
  animation-name: phaser;
  animation-iteration-count: infinite;
  animation-duration: 5s;
}

.create-event-page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 500px));
  grid-gap: 5px;
  gap: 5px;
  padding: 0 20px 0 20px;
  place-content: center;
  transition: all 500ms;
}

.create-event-page h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  color: #ddd;
}

.create-event-form-wrapper {
  width: 100%;
}

.form-problem-statements,
.metrics {
  padding: 10px;
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-content: flex-start;
}

.create-event-form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: space-between;
  grid-gap: 6px;
  gap: 6px;
  padding: 10px;
}

.create-event-form > h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
}

.create-event-form-fields {
  display: flex;
  flex-direction: column;
}

.create-event-form-fields > span {
  font-size: 1rem;
  color: #ccc;
  padding: 3px;
}

.create-event-form-fields > input,
.create-event-form-fields > textarea {
  background-color: #171717;
  outline: none;
  border: 0;
  height: 3rem;
  color: #fff;
  font-size: 1.3rem;
  padding-left: 10px;
  border-radius: 4px;
}

.create-event-form-fields > textarea {
  min-height: 100px;
  max-height: 150px;
  font-size: 1.2rem;
  max-width: 100%;
  min-width: 100%;
}

.create-event-form-fields > input::-webkit-input-placeholder {
  color: rgb(134, 134, 134);
  font-size: 1rem;
  text-align: center;
}

.create-event-form-fields > input:-ms-input-placeholder {
  color: rgb(134, 134, 134);
  font-size: 1rem;
  text-align: center;
}

.create-event-form-fields > input::-ms-input-placeholder {
  color: rgb(134, 134, 134);
  font-size: 1rem;
  text-align: center;
}

.create-event-form-fields > input::placeholder {
  color: rgb(134, 134, 134);
  font-size: 1rem;
  text-align: center;
}

.create-event-form-fields > input:focus,
.create-event-form textarea:focus {
  border: 1px solid white;
}

.create-event-form-fields > input:hover,
.create-event-form textarea:hover {
  border: 1px solid #aaa;
}

.submit-event {
  width: 100%;
  margin-top: 20px;
  height: 2.8rem;
  background-color: #335677;
  outline: none;
  border: 0;
  border-radius: 4px;
  color: #fff;
  transition: all 500ms;
  font-size: 1.3rem;
}

.submit-event:hover {
  background-color: #fff;
  color: #000;
}

.problem-statement,
.metric {
  border: 0;
  border-radius: 4px;
  max-width: 100%;
  min-width: 20%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-top: 5px;
  overflow: hidden;
  min-height: 20px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: #171717;
  display: flex;
  justify-content: space-between;
}

.remove-problem-statement,
.remove-metric {
  background-color: #201010;
  border: 0;
  border-left: 1px solid #db0f0f;
  outline: none;
  color: #ff7979;
  width: 35px;
  padding: 5px;
  font-size: 1rem;
  transition: all 500ms;
}

.remove-problem-statement:hover,
.remove-metric:hover {
  cursor: pointer;
  background-color: #db0f0f;
  color: #fff;
}

.ps-text {
  padding: 10px;
  width: 100%;
  transition: all 500ms;
  font-size: 1.1rem;
}

.ps-text:hover {
  color: rgb(255, 220, 220);
}

.form-problem-statements > h3,
.form-problem-statements > h2,
.metrics > h3,
.metrics > h2 {
  text-align: center;
  width: 100%;
}

.metric-name,
.metric-score {
  padding: 10px;
  font-size: 1.1rem;
}

.metric-score {
  color: #cecece;
}

.header > ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}

.admin-home {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.event-name {
  font-family: 'Azonix', sans-serif;
  color: #fff;
  font-size: 25px;
}

.reviews-list {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.review-button {
  background-color: #171717;
  outline: 0;
  margin: 5px;
  padding: 15px;
  border: 0;
  border-radius: 4px;
  width: 250px;
  color: #fff;
  font-size: 1rem;
  transition: all 500ms;
}

.review-button:hover {
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  transform: translateY(-3px) scale(1.05);
  background-color: #fff;
  color: #171717;
}

.event {
  margin-bottom: 2.7rem;
}

.review-overlay {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.897);
  display: grid;
  place-items: center;
}

.review-card {
  width: 80%;
  height: 80%;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: space-around;
}

.review-card-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 10px;
  align-items: flex-end;
}

.review-card-nav > span {
  display: flex;
}

.collapse-review {
  background-color: #383737;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  padding: 8px;
  margin-top: 5px;
  color: #fff;
  border: 0;
  width: 100px;
  transition: all 500ms;
}

.collapse-review:hover {
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  background-color: #fff;
  transform: scale(1.02);
  color: #000;
}

.collapse-review:focus {
  outline: none;
  border: 0;
}

.review-event-name {
  font-family: 'Poppins', sans-serif;
  padding: 8px;
}

.event-review-no {
  font-family: 'Poppins', sans-serif;
  padding: 8px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.review-body {
  height: 100%;
}

.no-teams {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin: 100px;
  font-size: 2rem;
}

/* The entire particpants page excluding navbar */
.participant-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container for different things below */

/* The container for participant events
 * There are 1 to 3 of these in the DOM,
 * One for unregistered, one for leaders, one for normiess
 */
.participants-event-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-status-container {
  width: 45%;
}

@media screen and (max-width: 1280px) {
  .event-status-container {
    width: auto;
  }
}

/* css for "Registered Events" and "Unregistered events"
 * As many instances as .participants-events-container
 */
.event-status-title {
  margin-top: 40px;
  font-family: 'Azonix', sans-serif;
  font-size: 2.2rem;
  font-weight: 100;
  text-align: center;
  margin-bottom: 15px;
}

/* Generic container for each event, regardless of regisred or not */
.participant-event-card {
  padding: 9px 10px;
  margin: 0 0 15px 0;
  width: 100%;
  background-color: #171717ea;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Overpass', sans-serif;
}

.participant-event-card > * {
  margin: 0 9px;
}

/* The name of the event in participant-event-card */
.ppt-event-name {
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
}

/* The container for the two buttons in the participant-event-card */
.participant-event-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.participant-event-buttons > * {
  margin: 0 9px;
}

/* The first button in .participant-event-buttons
 * Is either 'register' or 'submit'
 */
.ppt-event-primary-button {
  border: 0;
  margin: 8px 0;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 7px;
  color: #fff;
  background-color: #0083ff;
  height: 2.9rem;
  transition: all 500ms;
  font-family: 'Poppins', sans-serif;
}

.ppt-event-team-button {
  border: 0;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 7px;
  color: #fff;
  background-color: #0083ff;
  height: 2.9rem;
  transition: all 500ms;
  font-family: 'Poppins', sans-serif;
}

.ppt-event-primary-button:hover {
  background-color: #fff;
  color: #000;
}

.event-details-button {
  font-family: 'Poppins', sans-serif;
  padding: 7px;
  background-color: inherit;
  border: 1px solid white;
  border-radius: 4px;
  height: 2.9rem;
  color: #fff;
  font-size: 1.4rem;
  transition: all 500ms;
}

.event-details-button:hover {
  background-color: #fff;
  color: #000;
}

/* Handle everything above for small screens */
@media screen and (max-width: 580px) {
  .event-status-title {
    font-size: 1.4rem;
  }

  .participants-event-container:last-child {
    margin-top: 25px;
    border-top: 1px solid white;
    padding-top: 7px;
  }

  .participant-event-card {
    flex-direction: column;
    font-size: 1.5rem;
  }

  .participant-event-card > * {
    margin: 5px;
  }

  .event-details-button {
    font-size: 1.3rem;
  }

  .ppt-event-name {
    font-size: 1.3rem;
  }
}

.unreg-event-details-container {
  position: fixed;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 5;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  min-width: 100%;
  min-height: 100%;
  overflow-y: auto;
}

/* ugly stuff for firefox users as a punishment */
@-moz-document url-prefix() {
  .unreg-event-details-container {
    background-color: #232526;
  }
}

.unreg-event-details,
.submission-modal {
  margin: 0 auto;
  min-width: 600px;
  width: 600px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  place-items: center;
  background: #232526;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 30px;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;
}

.unreg-event-details-topbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.event-details-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
}

.event-details-description h2 {
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
}

.event-details-description {
  padding-bottom: 10px;
}

.event-details-title > h1 {
  font-size: 2rem;
  font-weight: 700;
}

.event-details-title > h3 {
  font-size: 1.2rem;
  font-weight: normal;
  color: rgb(179, 179, 179);
}

.unreg-event-detail-close {
  background-color: #171717;
  color: #fff;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px 5px 10px;
  border: 1px solid white;
  border-radius: 4px;
  transition: all 500ms;
  font-size: 1.2rem;
  font-weight: 500;
}

.unreg-event-detail-close:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000;
}

.event-details-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.event-details-body > * {
  margin: 4px 0;
}

.unreg-event-ps {
  display: flex;
  flex-direction: column;
  padding-bottom: 7px;
}

.unreg-event-teamSize {
  padding-bottom: 6px;
}

.unreg-event-teamSize > h2 {
  border-bottom: 1px solid white;
  padding: 10px 0;
  margin-bottom: 10px;
}

.unreg-event-metrics {
  padding-bottom: 7px;
}

.event-details-body li {
  list-style: none;
}

.event-details-body h2 {
  border-bottom: 1px solid white;
  padding: 10px 0;
  margin-bottom: 10px;
}

.ppt-event-register-button:hover {
  background-color: #335677;
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .unreg-event-details,
  .submission-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.create-team-overlay {
  width: 500px;
  padding: 20px;
  background-color: #171717;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.create-team-form {
  width: 80%;
}

.create-team-form > label {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 200;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 10px;
  width: 100%;
}

.create-team-input {
  width: 100%;
  background-color: #fffd;
  border: 0;
  border-radius: 4px;
  color: #000;
  height: 2rem;
  font-size: 1.5rem;
  padding: 6px;
  text-align: center;
}

.create-team-input:focus {
  outline: none;
  border: 1px solid hsl(241, 77%, 66%);
}

.create-team-button {
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  color: #000;
  width: 80%;
  height: 2.2rem;
  font-size: 1.5rem;
  transition: all 500ms;
}

.create-team-button:hover {
  background-color: #000;
  color: #fff;
}

.create-team-button:focus {
  background-color: #000;
  color: #fff;
}

.jointeam-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* background-color: #312e2e; */
  background: #232526;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* background: inherit; */
  display: grid;
  place-items: center;
}

.jointeam-container {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}

.jointeam-label {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  font-weight: bold;
  grid-gap: 7px;
  gap: 7px;
  padding-bottom: 10px;
}

.jointeam-input {
  height: 2.4rem;
  max-width: 400px;
  min-width: 300px;

  /* background-color: #171717; */
  background-color: inherit;
  border: 1px solid white;
  border-radius: 4px;
  color: #fff;
  font-size: 1.4rem;
  padding: 7px;
  transition: all 500ms;
}

.jointeam-input:focus {
  border: 1px solid gray;
  background-color: #fff;
  color: #000;
  outline: none;
}

.jointeam-input:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid rgb(78, 78, 78);
}

.jointeam-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.jointeam-button,
.jointeam-back-button {
  height: 2.7rem;
  font-size: 1.2rem;
  border: 0;
  background-color: #0083ff;
  color: #fff;
  padding: 7px;
  width: 49%;
  outline: none;
  border-radius: 4px;
  transition: all 500ms;
}

.jointeam-back-button {
  border: 1px solid gray;
  background-color: inherit;
}

.jointeam-error {
  min-width: 100%;
  text-align: center;
  color: red;
  padding: 14px;
  font-size: 1.3rem;
}

.jointeam-success {
  min-width: 100%;
  text-align: center;
  padding: 14px;
  color: rgb(90, 90, 207);
}

.teamcode {
  width: 100%;
  overflow: hidden;
  border: 0;
  background-color: #335677;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 14px;
  text-align: center;
  border-radius: 4px;
}

.teamcode > h2 {
  border: 1px solid white;
  border-radius: 4px;
  margin: 16px;
  padding: 8px;
}

.teamcode > h2:first-child {
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.teamcode > button {
  background-color: inherit;
  border: 1px solid white;
}

[teamcode=""] {
  display: none;
}

[created]:not([created=""]) {
  display: none;
}

.jointeam-overlay button:hover {
  background-color: #fff;
  color: #000;
}

.event-register {
  position: fixed;
  top: 0;
  left: 0;
  height: clamp(100%, 100%, 100%);
  width: clamp(100%, 100%, 100%);

  /* background: #171717; */
  background: #232526;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: grid;
  place-items: center;
}

@media screen and (max-width: 530px) {
  .event-register > div {
    display: flex;
    flex-direction: column;
  }
}

.join-team-button,
.event-create-team-button,
.event-register-back {
  margin: 5px;
  border-radius: 7px;
  background-color: inherit;
  background-color: #0083ff;
  color: #fff;
  height: 3.2rem;
  border: 0;
  font-size: 1.7rem;
  padding: 0 14px;
  transition: all 500ms;
}

.event-register-back {
  background-color: inherit;
  border: 1px solid gray;
}

.join-team-button:hover,
.event-create-team-button:hover,
.event-register-back:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.1) translateX(1.1);
}

[success]:not([success=""]) {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.success-join-request {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-join-request * {
  margin: 10px;
}

.event-submission-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: inherit;
  min-width: 100%;
  min-height: 100%;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: grid;
  place-items: center;
  overflow-y: auto;
}

.submission-body {
  width: 100%;
}

.submission-abstract {
  width: 100%;
  text-align: center;
}

.abstract-title {
  text-align: left;
}

.abstract-textarea,
.project-link-textarea {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 150px;
  max-height: 280px;
  outline: none;
  border: 1px solid white;
  font-size: 1.3rem;
  padding: 7px;
  color: #fff;
  background-color: inherit;
}

.project-link-textarea {
  min-height: 50px;
  max-height: 88px;
}

.submission-error {
  min-width: 100%;
  text-align: center;
  color: rgb(133, 18, 18);
  background-color: inherit;
}

.event-details-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-details-footer > * {
  margin: 5px;
}

.manage-team-name {
  border-bottom: 1px solid white;
}

.manage-team-body {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowraps;
  justify-content: space-evenly;
  border-bottom: 1px solid white;
  padding: 8px;
}

.team-members,
.waiting-members {
  padding: 20px;
}

.add-to-team {
  background-color: inherit;
  border: 0;
  margin-right: 10px;
  color: white;
  transition: all 500ms;
  transform: scale(1.1);
}

.add-to-team:hover {
  transform: scale(1.15);
}

@media screen and (max-width: 550px) {
  .manage-team-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .waiting-members {
    padding-top: 15px;
  }
}

.manage-team-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.delete-team-button,
.leave-team-button {
  border: 1px solid white;
  color: #fff;
  background-color: inherit;
  border-radius: 9px;
  padding: 5px 13px;
  font-size: 1.5rem;
  margin: 8px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transition: all 500ms;
}

.delete-team-button:hover,
.leave-team-button:hover {
  background-color: #fff;
  color: #000;
}

.wishes-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wish-reload {
  margin-top: 10px;
  padding: 9px;
  border: 1px solid white;
  border-radius: 7px;
  background-color: inherit;
  color: white;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  transition: all 500ms;
}

.wish-reload:hover {
  background-color: #fff;
  color: #000;
}

@font-face {
  font-family: 'Azonix';
  src:
    url(/static/media/Azonix.9091792c.otf),
    url(https://db.onlinewebfonts.com/c/50cd2aad9c8f35800bb6beac3ad42f16?family=Azonix);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);
  background-image: url(/static/media/Background.2e2d76ec.svg);
  background-repeat: unset;
  background-size: auto;
  color: white;
  padding: 20px;
}

.container {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

button:hover {
  cursor: pointer;
}

.login-error {
  width: 100%;
  color: red;
  font-size: 1.1rem;
  text-align: center;
  padding: 10px;
}

.links {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.links button,
.links a,
.logout-button {
  font-family: 'Azonix', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  margin: 0 1rem;
  background-color: inherit;
  border: 0;
  transition: all 300ms;
}

.logout-button {
  border: 4px solid #122c44;
  border-radius: 4px;
  padding: 6px;
  padding-top: 10px;
  align-items: center;
}

.logout-button:hover,
.logout-button > a:hover {
  color: #04d9ff;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
}

.links > button:hover,
.links > a:hover {
  color: #04d9ff;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: rgba(2, 8, 20, 0.1) 0 0.35em 1.175em, rgba(2, 8, 20, 0.08) 0.175em 0.5em;
  transform: translateY(-3px) scale(1.05);
}

.links > button:focus {
  border: none;
  outline: none;
}

.active-link {
  color: #04d9ff;
}

